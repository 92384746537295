import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const MembershipsStyle = () => {
  const { t } = useTranslation();
  const memberships = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(/memberships/)/" } }
      ) {
        edges {
          node {
            frontmatter {
              link
              featuredImage {
                childImageSharp {
                  gatsbyImageData(height: 150, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="customers-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <StaticImage
              src="../../assets/images/sun-icon.jpg"
              alt="about"
              style={{ verticalAlign: "middle", marginRight: "5px" }}
              width={32}
              height={32}
            />
            {t("Mitgliedschaften")}
          </span>
          <h2>{t("Company - Membership - Title - 1")}</h2>
          <p>{t("Company - Membership - Text - 1")}</p>
        </div>

        <div className="row">
          {memberships.allMarkdownRemark.edges.map((membership) => (
            <div className="col-lg-4 col-md-4" key={Math.random()}>
              <a
                href={membership.node.frontmatter.link}
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-customers-box">
                  <GatsbyImage
                    alt="Members"
                    image={
                      membership.node.frontmatter.featuredImage.childImageSharp
                        .gatsbyImageData
                    }
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MembershipsStyle;
