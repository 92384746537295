import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MembershipsStyle from "../components/Company/MembershipStyle";

const Membersships = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Mitgliedschaften")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Firma")}
      />
      <MembershipsStyle />
      <Footer />
    </Layout>
  );
};

export default Membersships;

export const pageQuery = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
